import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Grid, Input, Modal, ModalBody, ModalCloseButton, ModalContent, 
    ModalFooter, ModalHeader, ModalOverlay,  Select as Shivam,
    Stack,
    useToast, } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import LoginContext from '../../store/loginContext';
import { IClientsTable, IJobCategoryTable, IPortalProfileTable, IPortalTable, IusersTable } from '../../interfaces/Itable';
import useDataFetch from '../../hook/useDataFetch';
import axios, { AxiosError } from 'axios';
import api from '../../utils/api';
import LoadingSpinner from '../UI/loadingSpinner/LoadingSpinner';




interface FormData {
    portal: string;
    client: string;
    profile: string;
    upsellType: string;
    currency: string;
    estimatedHours?: string;
    cost?: number;
    grossCost: number;
    projectDeadline: string;
    jobCategory: string[];
    userProfile:string;
    upsellTitle: string;
    upsellLink: string;
    receivedAmount:number;
  }

const CreateUpsell = () => {
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);
  const navigate = useNavigate();
  const { id } = useParams();

 
 const [selected2, setSelected2] = useState<{
    value: string;
    label: string;
  }>({
    value:"",
    label: ""
  });

  const [formData, setFormData] = useState<FormData>({
    portal: '',
    client: '',
    profile: "",
    upsellType: '',
    currency: '',
    estimatedHours: "0",
    cost: undefined,
    grossCost: 0,
    projectDeadline: new Date().toISOString().split('T')[0],
    jobCategory: [],
    userProfile:"",
    upsellTitle: "",
    upsellLink: "",
    receivedAmount:0
  });

const PortalUrl = `${process.env.REACT_APP_API_URL}/api/portal`
  const PortalProfileUrl = `${process.env.REACT_APP_API_URL}/api/portal/profile`
  const ClientUrl = `${process.env.REACT_APP_API_URL}/api/portal/clients`
  const PortalCategoryUrl = `${process.env.REACT_APP_API_URL}/api/portal/category`
  const url = `${process.env.REACT_APP_API_URL}/api/users`;

  const [loadingid, setLoadingID] = useState(false)
  const { data, loading } = useDataFetch<IPortalTable[]>(PortalUrl);
  const { data: clientData,refetch } = useDataFetch<IClientsTable[]>(ClientUrl);
  const { data: profileData,  } = useDataFetch<IPortalProfileTable[]>(PortalProfileUrl);
  const { data: categoryData,} = useDataFetch<IJobCategoryTable[]>(PortalCategoryUrl);
  const { data:users, loading:userloading } = useDataFetch<IusersTable[]>(url);

  const [rates, setRates] = useState(0);
  const [price, setPrice] = useState<number | null>(null);
  const [portalName, setportalName] = useState({})
  const apiKey = '98a265629f6f37c3eaf2e783';
  const [selectedPortal, setSelectedPortal] = useState<IPortalTable | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const toast=useToast();

  useEffect(() => {

    async function getData() {
      try {

        setLoadingID(true)
        const res = await api.get('/portal/upsell/' + id);
        setportalName(res?.data?.portal?.portalName)

        setLoadingID(false)
        if (!res.data) {
          return
        }
        const {
          portal ,
          client ,
          profile ,
          upsellType ,
          currency,
          estimatedHours,
          cost,
          grossCost,
          projectDeadline,
          jobCategory,
          userProfile,
          upsellTitle,
          upsellLink,
          receivedAmount

        } = res.data;
        setSelected2({
          value: client?._id,
          label: client?.clientName
        })
        // Convert jobCategory to an array of _id strings if it's an array
        const jobCategoryIds = Array.isArray(jobCategory)
          ? jobCategory.map((category: any) => category._id.toString()) // Ensure _id is converted to string if necessary
          : jobCategory?._id
            ? [jobCategory._id.toString()] // Ensure _id is converted to string if necessary
            : [];



        setFormData({
    
          portal: portal?._id || '',
            client: client?._id || '',
          profile: profile?._id || '',
          upsellType:upsellType,
          currency,
          estimatedHours,
          cost,
          grossCost,
          projectDeadline: new Date(projectDeadline).toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
          jobCategory: jobCategoryIds,
          userProfile,
          upsellTitle:upsellTitle,
          upsellLink:upsellLink,
          receivedAmount,
           });
      } catch (err) {
        console.log(err);
      }
    }
    if (id) getData();
  }, [id]);


  useEffect(()=>{
    if (formData?.portal && data) {
      const selectedPortal = data.find((portal) => portal._id === formData?.portal);
      if (selectedPortal) {
    setSelectedPortal(selectedPortal);
      }
    }
  },[formData?.portal,data])

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleCreateClient = async () => {


    try {
      const res = await api.post('/portal/create_client', { clientName ,creator: user?._id});
      if (res.data) {
        console.log('Client created successfully:', res.data);
        refetch()
        handleCloseModal();
      }
    } catch (err) {
      console.error('Error creating client:', err);
    }

  };

  const handleChangeClientName = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setClientName(event.target.value);
  };



  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      
      if (name === 'upsellType' && value === 'fixed') {
        return {
          ...prevData,
          [name]: value,
          estimatedHours: "0",
          cost: undefined,
        };
      }
  return {
        ...prevData,
        [name]: value,
      };
    });

    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]:value
    // }));
};

  const handleClient = (value: { value: string; label: string }) => {



    setFormData((prevData) => ({
      ...prevData,
      "client": value.value
    }));
    setSelected2(value)


  };

  useEffect(() => {

    if (data) {

      const portal = data?.find(item =>
        formData?.portal
          ? item?._id === formData.portal
          : item?.portalName === portalName
      );
      // const portal = data?.find(item => item?._id === formData?.portal);

      if (portal) {
        const calculatedPrice = formData?.grossCost - ((formData?.grossCost * portal?.charges) / 100);
        setPrice(calculatedPrice);

      } else {
        console.log("No matching portal found");
      }

      console.log("Data is null");
    }
  }, [portalName, data, formData.grossCost, formData.portal]);



  useEffect(() => {
    if (formData?.upsellType === 'hourly' && formData?.estimatedHours && formData?.cost) {

      setFormData((prevData) => ({
        ...prevData,
        grossCost: Number(formData?.estimatedHours)! * formData?.cost!
      }));
    }
  }, [formData.upsellType, formData.estimatedHours, formData.cost]);

  useEffect(() => {
    if (formData?.estimatedHours && formData?.cost) {

      const [hoursStr, minutesStr] = formData?.estimatedHours?.split('.');

      const hours = Number(hoursStr);
      const minutes = Number(minutesStr) || 0;


      const totalHours = hours + (minutes / 60);

      const calculation = (totalHours * formData.cost * rates).toFixed(2)

      setFormData({ ...formData, grossCost: Number(calculation) })
    }
  }, [formData.estimatedHours, formData.cost, formData?.currency, rates])


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let url = id ? '/portal/update_upsell/' + id : `/portal/create_upsell`;
      let creator = user?._id;
      let remianingCost = price;
      const res = id ? await api.patch(url, { ...formData,remianingCost }) : await api.post(url, { ...formData, creator,remianingCost });
      if (res.data) {
        
        toast({
          title: id ?'Upsell successfully updated.':"Upsell successfully created.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        navigate("/upsell")
      }
    } catch (err) {
      if (err instanceof AxiosError ) {
        toast({
       
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error(err);
      }
  }
      }

  const formKeys = Object.keys(formData) as Array<keyof FormData>;
  const formatLabel = (label: string) => {
    if (label === 'grossCost') {
      return 'Gross Cost (USD)';
    }

    return label
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
      
  };



  useEffect(() => {
   const fetchRates = async () => {
      try {
        const response = await axios.get(`https://v6.exchangerate-api.com/v6/98a265629f6f37c3eaf2e783/latest/${formData?.currency}`);
        setRates(response.data.conversion_rates.USD);

      } catch (error) {
        console.error('Error fetching exchange rates', error);
      }
    };
    if(formData?.currency){
      fetchRates();
    }
  }, [apiKey, formData.currency]);


  return (
    <>

{loadingid ? <LoadingSpinner /> : <>
          <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Client</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <Input
                  placeholder='Enter client name'
                  value={clientName}
                  onChange={handleChangeClientName}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button bg= "#F36E21" color="white"  _hover={{color:"black", bg:"#dfd6d68a"}} mr={3} onClick={handleCreateClient}>
                Create
              </Button>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </ModalFooter>
            </ModalContent>
          </Modal>
        <Box mb={10} mt={-5}>
          {/* {pathElements.slice(0, 1).map((element, index) => (
            <span key={index} style={{ color: "black" }}>
              <Link to={`/${pathElements.slice(0, index + 1).join('/')}`} onClick={() => handleClick(index)}>
                {element}
              </Link>
              {index !== pathElements.length - 1 && ' / '}
            </span>
          ))} */}
          {/* <Heading color='#F36E21'>{id ? 'Edit Proposal' : 'Create Proposal'}</Heading> */}
          <form onSubmit={handleSubmit}>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            {formKeys.map((key) => {

              if (key === 'upsellType') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Shivam
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Select ${formatLabel(key)}`}
                    >
                      {["hourly", "fixed"].map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </Shivam>
                  </FormControl>
                );
              }
              else if (key === 'portal'){

                return(
                    <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Shivam
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    placeholder={`Select ${formatLabel(key)}`}
                    required
                  >
                    {!loading && data && data.map((portal) => (
                      <option key={portal._id} value={portal._id}>
                        {portal.portalName}
                      </option>
                    ))}
                  </Shivam>
                  </FormControl>
                )
              }
              else if( key === "profile"){
                return(
                  <FormControl key={key} 
                  isRequired ={ !(selectedPortal?.portalName === "Direct" || selectedPortal?.portalName=== "Direct Paypal")}>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                  <Shivam
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  placeholder={`Select ${formatLabel(key)}`}
                
                >
               {(user?.role==="admin" || user?.role==="manager") &&
                   profileData && profileData.map((profile: any) => (
                    profile?.portal?._id === formData.portal && <option key={profile._id} value={profile._id}>
                      {profile.profileName}
                    </option>
                  ))
                  }

                </Shivam>
                </FormControl>
                )
              }
              else if (key === 'currency') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>currency</FormLabel>
                    <Shivam
                      placeholder='Select currency'
                      name={key}
                      onChange={handleChange}
                      value={formData[key]}
                    >
                      {["USD", "INR","AUD", "GBP", "EUR", "CAD","NZD","SGD",].map(currency => (
                        <option key={currency} value={currency}>{currency}</option>
                      ))}
                    </Shivam>
                  </FormControl>
                )
              }
              else if (key === 'client') {
                return (
                  <FormControl key={key} isRequired >
                    
                    <Flex justify="space-between" align="center">
        <FormLabel>{formatLabel(key)}</FormLabel>
        <Box  style={{margin:"3px",background:"#F36E21",padding:"2px 5px 2px 5px",color:"white",borderRadius:"5px"}} 
    
        onClick={handleOpenModal}>Add Client</Box>
      </Flex>
                    <Select
                              menuPlacement='top'
                              value={selected2}
                              onChange={(selectedOption) => handleClient(selectedOption as any)}
                              name="colors"
                              options={clientData?.map((el) => ({
                                value: el._id,
                                label: el.clientName,
                              })) as any}

                              placeholder="Select client"
                              closeMenuOnSelect={false}
                              size="md"
                            />
                  </FormControl>
                );
            } else if (key === 'projectDeadline') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{`${formatLabel(key)}`}</FormLabel>
                    <Input
                      name={key}
                      type='date'
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                      min={new Date().toISOString().split('T')[0]} 
                    />
                  </FormControl>
                )
              }
              else if (key === 'receivedAmount') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{`${formatLabel(key)}`}</FormLabel>
                    <Input
                      name={key}
                      type='number'
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                    />
                  </FormControl>
                )
              }
              else if (key === "jobCategory"){
                return(
                    <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                <Select
                required
                isMulti
                name={key}
                value={formData.jobCategory.map((categoryId) => ({
                  value: categoryId,
                  label: categoryData?.find((category) => category._id === categoryId)?.jobName || '',
                }))}
                options={categoryData?.map((category) => ({
                  value: category._id.toString(), // Ensure _id is converted to string if necessary
                  label: category.jobName,
                }))}
                onChange={(selectedOptions) => {
                  const values = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                  setFormData((prevData) => ({
                    ...prevData,
                    jobCategory: values,
                  }));
                }}
                placeholder="Select some categories..."
                closeMenuOnSelect={false}
              />
              </FormControl>
                )
             
              }
              else if (key === 'estimatedHours' && formData.upsellType === 'hourly') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Input
                      name={key}
                      type='number'
                      value={formData[key] || ''}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                    />
                  </FormControl>
                )
              }
              else if (key === 'cost' && formData.upsellType === 'hourly') {
                return (
                  <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Input
                      name={key}
                      type='number'
                      value={formData[key] || ''}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                    />
                  </FormControl>
                )
              }
              else if (key === 'userProfile'){

                return(
                    <FormControl key={key} isRequired>
                    <FormLabel>{formatLabel(key)}</FormLabel>
                    <Shivam
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    placeholder={`Select ${formatLabel(key)}`}
                    required
                  >
                    {!userloading && users && users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.name}
                      </option>
                    ))}
                  </Shivam>
                  </FormControl>
                )
              }
         
              else if (key === 'grossCost') {
                return (
                  <FormControl key={key} isRequired >
                <Flex align="center">
                  <FormLabel flex="1">{formatLabel(key)}</FormLabel>
                    
                        <Box>Remaining Cost: ${price?.toFixed(2)}</Box>
                     
                    </Flex>
                    <Input
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${formatLabel(key)}`}
                      readOnly={formData.upsellType === 'hourly'}
                    />
                  </FormControl>
                )
              }
            else if (key === 'upsellTitle') {
              return (
                <FormControl key={key} 
                isRequired={ !(selectedPortal?.portalName === "Direct" || selectedPortal?.portalName=== "Direct Paypal")}>
                  <FormLabel>{`${formatLabel(key)}`}</FormLabel>
                  <Input
                    name={key}
                    type='text'
                    value={formData[key]}
                    onChange={handleChange}
                    placeholder={`Enter ${formatLabel(key)}`}
                  />
                </FormControl>
              )
            }
            else if (key === 'upsellLink') {
              return (
                <FormControl key={key} 
                isRequired={ !(selectedPortal?.portalName === "Direct" || selectedPortal?.portalName=== "Direct Paypal")}>
                  <FormLabel>{`${formatLabel(key)}`}</FormLabel>
                  <Input
                    name={key}
                    type='text'
                    value={formData[key]}
                    onChange={handleChange}
                    placeholder={`Enter ${formatLabel(key)}`}
                  />
                </FormControl>
              )
            }
          
              return null;
            })}
          </Grid>
          <Box mt={10}>
            <Stack direction='row' justify={'flex-end'} spacing={10}>
              <Button bg="#F36E21" color="white" _hover={{color:"black",bg:"#dfd6d68a"}} type="submit">
                {t('Submit')}
              </Button>
              <Button onClick={() => navigate("/upsell")}>{t('Cancel')}</Button>
            </Stack>
          </Box>
        </form>
        </Box>
       </>
        }
       </>
        
   
   
  );
};

export default CreateUpsell;
