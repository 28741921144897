import React, { useContext, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import langContextObj from "../../store/langContext";
import Button from "../UI/button/Button";
import Input from "../UI/input/Input";
import classes from "./Register.module.scss";
import axios, { AxiosError } from "axios";
import { Center, FormControl, Heading, useToast } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
import { StreamChat } from "stream-chat";


const cookies = new Cookies();



function RegisterBox() {
  const langCtx = useContext(langContextObj);
  const nameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const errorMessageRef = useRef<HTMLSpanElement>(null!);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false); 
  const apiKey = 'r7d6wr2fesgf';
  const client = StreamChat.getInstance(apiKey);
  
  const toast=useToast();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function loginHandler(e: React.FormEvent) {
    e.preventDefault();

    const nameValue = nameRef.current?.value.trim();
    const emailValue = emailRef.current?.value.trim();
    const passwordValue = passwordRef.current?.value.trim();

    // Name validation
    if (nameValue && nameValue.length < 3) {
      errorMessageRef.current?.setAttribute(
        "style",
        "display: inline-block; opacity: 1"
      );
      errorMessageRef.current.innerText =
        "Name length should be equal to 3 or more than that";
      nameRef.current?.focus();
      return;
    } else {
      nameRef.current?.blur(); // Remove focus if the error is resolved
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(!emailValue){
      errorMessageRef.current?.setAttribute(
        "style",
        "display: inline-block; opacity: 1"
      );
      errorMessageRef.current.innerText = "Email is required";
      emailRef.current?.focus();
      return;
    }
    if (!emailRegex.test(emailValue || '')) {
      errorMessageRef.current?.setAttribute(
        "style",
        "display: inline-block; opacity: 1"
      );
      errorMessageRef.current.innerText = "Invalid email format";
      emailRef.current?.focus();
      return;
    } else {
      emailRef.current?.blur(); // Remove focus if the error is resolved
    }

    // Password validation
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;
    if(!passwordValue){
      errorMessageRef.current?.setAttribute(
        "style",
        "display: inline-block; opacity: 1"
      );
      errorMessageRef.current.innerText = "Password is required";
      passwordRef.current?.focus();
      return;
    }
    if (!passwordRegex.test(passwordValue || '')) {
      errorMessageRef.current?.setAttribute(
        "style",
        "display: inline-block; opacity: 1"
      );
      errorMessageRef.current.innerText =
        "Password must be at least 6 characters, contain at least one uppercase letter, one number, and one special character";
      passwordRef.current?.focus();
      return;
    } else {
      passwordRef.current?.blur();
    }
    errorMessageRef.current?.setAttribute(
      "style",
      "display: inline-block; opacity: 1"
    );
    errorMessageRef.current.innerText = "Loading...";

    try {
      const signupResponse=  await axios.post(process.env.REACT_APP_API_URL+'/api/users/signup', 
        { name: nameRef.current?.value, email: emailRef.current?.value, password: passwordRef.current?.value })
        // .then((res) => {
        // alert("Your account is registered");
        toast({
          title: 'Account successfully created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

        const { token, userId,hashedPassword,name } = signupResponse?.data;

//  if(token) {
//             client.connectUser({
//                 id: userId,
//                 name: name,
//                 fullName: name,
//                 image: cookies.get('avatarURL'),
//                 hashedPassword:hashedPassword,
//                 phoneNumber: '',
//             }, token)
//         }
   
if (token) {
  // Ensure disconnection before reconnecting
  if (client?.user) {
    await client.disconnectUser();
  }

  await client.connectUser({
    id: userId,
    name: name,
    fullName: name,
    image: cookies.get('avatarURL'),
    hashedPassword: hashedPassword,
    phoneNumber: '',
  }, token);
}

       
     
        navigate("/")
       

      // }).catch((err) => {
      //   errorMessageRef.current?.setAttribute(
      //     "style",
      //     "display: inline-block; opacity: 1"
      //   );
      //   errorMessageRef.current.innerText= err?.response?.data?.message || err?.response?.data?.errors[0] || "Something went wrong!"
      // })

    } catch (err) {
      if (err instanceof AxiosError ) {
        errorMessageRef.current.innerText = 
        err.response?.data?.message ||
        err.response?.data?.errors?.[0] ||
        "Something went wrong!";
        toast({
       
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error(err);
        errorMessageRef.current.innerText = "Something went wrong!";
      }
  }
}




  return (

    <div
      className={`${classes.container} ${langCtx.lang === "hi" ? classes.rtl : ""
        }`}
    >
<div className={classes.loginBox}>
     <div className={classes.logo}>
         <Center> <Heading color={"#F36E21"} textDecoration={"underline"} 
         mt={10} mb={14} size={"md"}>
          
          
          {/* Aron Web Solutions */}
          <img src="awslogo.png" alt="awslogo"/>
          </Heading></Center>
     
          {/* <img src={images.logo} alt="aronweb" /> */}
        </div>
        <h2 className={classes.title}>{t("registerPage")}</h2>
        <form onSubmit={loginHandler} autoComplete="off">
          <Input
            ref={nameRef}
            type={"text"}
            id={"Name"}
            placeholder={"Enter your name"}
          />
          <Input
            ref={emailRef}
            type={"email"}
            id={"Email"}
            autocomplete="none"
            placeholder={"email"}
          />
          <FormControl className={classes.password_field}>
           <Input
            ref={passwordRef}
           type={showPassword ? "text" : "password"}
           autocomplete="new-password"
            id={"pass"}
            // value={"admin"}
            placeholder={"password"}
          />
         
        <span className={classes.eye_icon} onClick={togglePasswordVisibility}> {showPassword ? <Icon icon="icomoon-free:eye" />:<Icon icon="ion:eye-off-outline" /> }</span> 
        </FormControl>
          <span ref={errorMessageRef} className={classes.errorMessage}>
          </span>
          <Button type="submit">{"Create Account"}</Button>
          {/* <Link className={classes.routeLogin} to="/">
            {"Already have an account?"}
          </Link> */}

        </form>
      </div>

      <div className={classes.keyPic}>
        <img
          src={require("../../assets/images/Revenue-cuate.svg").default}
          alt="illustrator key"
        />
      </div>
    </div>
  );
}

export default RegisterBox;
