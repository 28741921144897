import React, { useState, useEffect, useContext } from 'react';
import {  Flex, Heading,Input, Select, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../components/UI/button/Button';
import LoginContext from '../store/loginContext';
import CustomTable from '../components/tables/customTable/CustomTable';
import { upsellHeader } from '../constants/tables';
import { IPortalTable, IusersTable, UpsellTable } from '../interfaces/Itable';
import useDataFetch from '../hook/useDataFetch';
import LoadingSpinner from '../components/UI/loadingSpinner/LoadingSpinner';

const Upsell = () => {
  const UpsellUrl = `${process.env.REACT_APP_API_URL}/api/portal/upsell`
  const url = `${process.env.REACT_APP_API_URL}/api/users`;
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);
  const navigate = useNavigate();
  const { data, loading ,refetch,error} = useDataFetch<UpsellTable[]>(UpsellUrl);
  const { data:users,} = useDataFetch<IusersTable[]>(url);
  const [selected, setSelected] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState('');
 
  const [jobCategory, setJobCategory] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
const [updatedData, setUpdatedData] = useState<UpsellTable[]>([]);


useEffect(() => {
  if (selected) refetch()
  }, [selected])


// useEffect(() => {
//   if (data && users) {
//     const updated = data.map(item => {
//       const user = users.find(user => user._id === item?.userProfile);
//       if (user) {
//         return { ...item, userName: user.name };
//       }
//       return item;
//     });
//     setUpdatedData(updated);
//   }
// }, [data, users]);

// useEffect(() => {
//   if (data) {
   
//     let filtered = data?.filter(
//       (item) =>
//         item?.profile?.profileName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         item?.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         item?.upsellLink?.toLowerCase().includes(searchTerm.toLowerCase())
//     );

// if (jobCategory) {
//       filtered = filtered?.filter((item) =>
//         item?.jobCategory?.some((category) =>{
       
//          return category?.jobName?.toLowerCase().includes(jobCategory.toLowerCase())}
//         )
//       );
//     }
    
    
//     if (startDate) {
//       console.log(startDate)
//       filtered = filtered.filter((item) =>
//         new Date(item.createdAt) >= new Date(startDate)
//       );
//       console.log(filtered,"start")
//     }

//     if (endDate) {
//       const adjustedEndDate = new Date(endDate);
//       adjustedEndDate.setHours(23, 59, 59, 999);
//       filtered = filtered.filter((item) => new Date(item.createdAt) <= adjustedEndDate);
   
//     }

//     setUpdatedData(filtered);
//   }
// }, [data, searchTerm, jobCategory, startDate, endDate]);

useEffect(() => {
  if (data && users) {
 
    const updated = data.map(item => {
      const user = users.find(user => user._id === item?.userProfile);
      if (user) {
        return { ...item, userName: user.name };
      }
      return item;
    });

    // Now, apply the filtering logic on the updated data
    let filtered = updated.filter(
      (item) =>
        item?.profile?.profileName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.userName?.toLowerCase().includes(searchTerm.toLowerCase())
         ||item?.upsellLink?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (jobCategory) {
      filtered = filtered.filter((item) =>
        item?.jobCategory?.some((category) =>
          category?.jobName?.toLowerCase().includes(jobCategory.toLowerCase())
        )
      );
    }

    if (startDate) {
      filtered = filtered.filter((item) =>
        new Date(item.createdAt) >= new Date(startDate)
      );
    }

    if (endDate) {
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setHours(23, 59, 59, 999);
      filtered = filtered.filter((item) => new Date(item.createdAt) <= adjustedEndDate);
    }

    // Finally, set the updated data
    setUpdatedData(filtered);
  }
}, [data, users, searchTerm, jobCategory, startDate, endDate]);



if (loading) {
  return <LoadingSpinner />;
}

if (error) {
  return <p>Error loading Upsell</p>; 
}

return (
    <>
      <section>
        <Flex mb={12} alignItems="center" justify="space-between" >
          <Heading color="#F36E21">{t('upsell')}</Heading>
          <Button outline onClick={() => navigate('/createupsell')}>
            {t('createUpsell')}
          </Button>
        </Flex>
        <Flex mb={4} gap={4} alignItems="center" justify="space-between">
          <Flex gap={4}>
            <Input

            
              type="text"
              w={'fit-content'}
              placeholder="Search by link/profile or username"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
        
            <Select
              w={'fit-content'}
              placeholder="Select job category"
              value={jobCategory}
              onChange={(e) => setJobCategory(e.target.value)}
            >
              <option value="designing">Designing</option>
              <option value="development">Development</option>
              <option value="marketing">Digital Marketing</option>
              <option value="graphics">Graphics</option>
            </Select>
          </Flex>
          <Flex gap={2} alignItems="center">
            <Text>From</Text>
            <Input
              type="date"
              w={'fit-content'}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Text>To</Text>
            <Input
              type="date"
              w={'fit-content'}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Flex>
        </Flex>
        {data && data.length > 0?
          <CustomTable key={Date.now()} headData={upsellHeader} bodyData={updatedData} limit={10} setSelected={setSelected} />
          :
          <Text textAlign="center" mt={8} fontSize="medium" fontWeight="bold" >No upsell records available at this time.</Text>
        }
      
      </section>
    </>
  );
};

export default Upsell;
