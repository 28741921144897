import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/UI/loadingSpinner/LoadingSpinner";
import CustomTable from "../components/tables/customTable/CustomTable";
import { customers, usersHeader } from "../constants/tables";
import useDataFetch from "../hook/useDataFetch";
import { IusersTable } from "../interfaces/Itable";
import { Flex, Heading } from "@chakra-ui/react";
import Button from '../components/UI/button/Button';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Register from "./Register";
const url = `${process.env.REACT_APP_API_URL}/api/users`;

function Users() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, error, loading ,refetch} = useDataFetch<IusersTable[]>(url);
  const [createUser,setCreateUser]=useState(false)
  const [selecteduser, setSelectedUser] = useState<string>("");
  
  let userTable;


  useEffect(() => {
if (selecteduser) refetch()
 }, [selecteduser])

  if (loading) {
    userTable = <LoadingSpinner />;
  }

  if (error) {
    userTable = (
      <CustomTable limit={10} headData={usersHeader} bodyData={[]} setSelected={setSelectedUser}/>
    );
  }

  if (!loading && data) {
    const sortedData = data?.sort((a, b) => a?.name.localeCompare(b?.name)); 
    userTable = (
      <CustomTable limit={10} headData={usersHeader} bodyData={sortedData}  setSelected={setSelectedUser}/>
    );
  }


 
  
  return (
    <section>
      {/* <h2 className="title">{t("users")}</h2> */}
      
      <Flex mb={4} alignItems="center" justify="space-between">
        
          <Heading color="#F36E21">  {t("users")}</Heading>
          {createUser? <Button outline onClick={()=>setCreateUser(!createUser)}>
            {t('Go Back')}
          </Button>
          :
          <Button outline onClick={()=>setCreateUser(!createUser)}>
          {t('createUser')}
        </Button>}
       
        </Flex>
        {createUser ? <Register/> : userTable}
    </section>
  );
}

export default Users;
