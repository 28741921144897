import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Select,
  useToast
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { IJobCategoryTable, IPortalProfileTable, IPortalTable } from '../../../../interfaces/Itable';
import useDataFetch from '../../../../hook/useDataFetch';
import api from '../../../../utils/api';
import { AxiosError } from 'axios';

interface EditPortalProfileProps {
  onClose: () => void;
  item: IPortalProfileTable;
  setSelected?: React.Dispatch<React.SetStateAction<string>>
}

const EditPortalProfile: React.FC<EditPortalProfileProps> = ({ onClose, item ,setSelected}: EditPortalProfileProps) => {
  const [formData, setFormData] = useState({
    portal: item.portal?._id || '', // Use the existing item's portal ID if available
    profileName: item.profileName || '',
    profileLink: item.profileLink || '',
    jobCategory: item.jobCategory?._id || '', // Use the existing item's jobCategory ID if available
  });
  const PortalCategoryUrl=`${process.env.REACT_APP_API_URL}/api/portal/category`
  const PortalUrl=`${process.env.REACT_APP_API_URL}/api/portal`

  const { data,  loading } = useDataFetch<IPortalTable[]>(PortalUrl); 
  
  const toast=useToast();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = async () => {
   
    try {
      const res = await api.patch(`/portal/update_profile/${item._id}`, formData);
      if (res.data) {
       
        if (setSelected) {
          setSelected(Date.now().toString());
        }
        toast({
          title: 'Portal Profile has been successfully edited.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      if (err instanceof AxiosError ) {
        toast({
       
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error(err);
      }
    }
    onClose();
  };

  return (
    <>
      <ModalBody pb={6}>
        <FormControl>
          <FormLabel>Portal name</FormLabel>
          <Select
            placeholder='Select option'
            name='portal'
            value={formData.portal}
            onChange={handleChange}
          >
         {!loading && data && data.map((portal) => ( 
              <option key={portal._id} value={portal._id}>
                {portal.portalName}
              </option>
           ))} 
          </Select>
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Profile Name</FormLabel>
          <Input
            placeholder="Profile Name"
            name="profileName"
            value={formData.profileName}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Profile link</FormLabel>
          <Input
            placeholder="Profile Link"
            name="profileLink"
            value={formData.profileLink}
            onChange={handleChange}
          />
        </FormControl>

        {/* <FormControl mt={4}>
          <FormLabel>Job Category</FormLabel>
          <Select
            placeholder='Select option'
            name='jobCategory'
            value={formData.jobCategory}
            onChange={handleChange}
          >
            {!categoryLoading && categoryData && categoryData.map((jobCategory) => (
              <option key={jobCategory._id} value={jobCategory._id}>
                {jobCategory.jobName}
              </option>
            ))}
          </Select>
        </FormControl> */}
      </ModalBody>

      <ModalFooter>
        <Button bg="#F36E21" color="white" _hover={{color:"black",bg:"#dfd6d68a"}} mr={3} onClick={handleEdit}>
          Edit
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default EditPortalProfile;
