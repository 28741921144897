export const customersHeader = [
  "ID",
  "userName",
  "proposalURL",
  "profile",
  // "cost",
  "connectsUsed",
  // "client",
  "status",
  "createdOn",
  "actions",
];
export const usersHeader = [
  "ID",
  "name",
  // "email",
  "target",
  "role",
  // "profilesAllocated",
  "actions",
];
export const customers = [
  {
    ID: 0,
    avatar:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    userName: "Zahra Mirzaei",
    portal:"upwork",
    profile:"Sunil Gautam",
    connectsUsed:"16",
    cost:"30$/h",
    client:"Shivam",
    status:"new",
    createdOn:"21 Nov",

    email: "zahra_mirzaei@gmail.com",
    phoneNumber: "+98 918 123 45 67",
    totalOrders: 804,
    totalSpend: "$120.00",
    location: "UK",
  },
];

export const productsHeader = [
  "ID",
  "product",
  "inventory",
  "price",
  "category",
  "actions",
];
export const products = [
  {
    ID: 0,
    pic: "https://images.unsplash.com/photo-1628773193539-ad29c647c071?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    product: "Apple AirPods",
    inventory: 30,
    price: "$200.00",
    category: "digital",
  },
  {
    ID: 1,
    pic: "https://images.unsplash.com/photo-1484704849700-f032a568e944?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    product: "NUBWO G06",
    inventory: 28,
    price: "$120.00",
    category: "digital",
  },
  {
    ID: 2,
    pic: "https://images.unsplash.com/photo-1607860087860-c46e865f6ab0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=426&q=80",
    product: "Hooded Sweatshirt",
    inventory: 10,
    price: "$10.00",
    category: "clothing",
  },
  {
    ID: 3,
    pic: "https://images.unsplash.com/photo-1587829741301-dc798b83add3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=865&q=80",
    product: "Keyboard",
    inventory: 50,
    price: "$50.00",
    category: "digital",
  },
  {
    ID: 4,
    pic: "https://images.unsplash.com/photo-1622470953794-aa9c70b0fb9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    product: "T-Shirt",
    inventory: 20,
    price: "$75.00",
    category: "clothing",
  },
  {
    ID: 5,
    pic: "https://images.unsplash.com/photo-1527443224154-c4a3942d3acf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    product: "LED Monitor",
    inventory: 31,
    price: "$510.00",
    category: "digital",
  },
  {
    ID: 6,
    pic: "https://images.unsplash.com/photo-1640025867572-f6b3a8410c81?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80",
    product: "Socks",
    inventory: 5,
    price: "$10.00",
    category: "clothing",
  },
  {
    ID: 7,
    pic: "https://images.unsplash.com/photo-1615663245857-ac93bb7c39e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=465&q=80",
    product: "Mouse",
    inventory: 3,
    price: "$16.00",
    category: "digital",
  },
  {
    ID: 8,
    pic: "https://images.unsplash.com/photo-1625753783470-ec2ab4efeeec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80",
    product: "Mielle",
    inventory: 36,
    price: "$170.00",
    category: "beauty",
  },
  {
    ID: 9,
    pic: "https://images.unsplash.com/photo-1556306535-0f09a537f0a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    product: "hat cap",
    inventory: 17,
    price: "$25.00",
    category: "clothing",
  },
  {
    ID: 10,
    pic: "https://images.unsplash.com/photo-1608571423902-eed4a5ad8108?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    product: "CeraVe",
    inventory: 7,
    price: "$220.00",
    category: "beauty",
  },
  {
    ID: 11,
    pic: "https://images.unsplash.com/photo-1620916566398-39f1143ab7be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    product: "Neutrogena",
    inventory: 30,
    price: "$205.00",
    category: "beauty",
  },
];

export const userhiredPorposal=[
  "ID",
 "proposalURL",
  "profile",
  "connectsUsed",
  "portal"
]

export const allocateprofile=[
  "ID",
 "Profile Name",
"Portal",
 "Total Proposal",
 "Hired Proposal",

]

export const upsellHeader=[
  "ID",
 "Profile Name",
"Profile",
"Upsell Url",
"Portal",
"createdOn",
"actions"


]

export const allocatetarget=[
  "ID",
 "Target",
"Date",
]