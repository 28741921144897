import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Button,
  useToast
} from '@chakra-ui/react';
import api from '../../../utils/api';
import { AxiosError } from 'axios';

interface CreateJobCatProps {
  onClose: () => void;
  setSelected3?:React.Dispatch<React.SetStateAction<string>>
}

const CreateJobCat: React.FC<CreateJobCatProps> = ({ onClose,setSelected3 }: CreateJobCatProps) => {
  const [formData, setFormData] = useState({
    jobName: '',
    desc: ''
  });
  const toast = useToast();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const createJobCategory = async() => {
   
    try {
      const res = await api.post(`/portal/create_category`, formData);
      if (res.data)
        if(setSelected3){
          setSelected3(Date.now().toString());
        }
     toast({
        title: 'Job category has been successfully created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      if (err instanceof AxiosError ) {
        toast({
       
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error(err);
      }
    }
    onClose();
  };

  return (
    <>
      <ModalBody pb={6}>
        <FormControl>
          <FormLabel>Job Category name</FormLabel>
          <Input
            placeholder='Development,Designing,...'
            name='jobName'
            onChange={handleChange}
            value={formData.jobName}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Desc</FormLabel>
          <Input
            placeholder='desc'
            name='desc'
            onChange={handleChange}
            value={formData.desc}
          />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button bg= "#F36E21" color="white" _hover={{color:"black",bg:"#dfd6d68a"}} mr={3} onClick={createJobCategory}>
          Create
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default CreateJobCat;
