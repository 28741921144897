import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LoginContextProvider } from './store/loginContext';
import { ThemeContextProvider } from './store/themeContext';
import { SidebarContextProvider } from './store/sidebarContext';
import { LangContextProvider } from './store/langContext';
import { ChakraProvider } from '@chakra-ui/react';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <LangContextProvider>
   <LoginContextProvider>
      <ThemeContextProvider>
        <SidebarContextProvider>
        <ChakraProvider  disableGlobalStyle={true}>

          <App />
          </ChakraProvider>
        </SidebarContextProvider>
      </ThemeContextProvider>
    </LoginContextProvider></LangContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
