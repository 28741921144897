import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Button,
 RadioGroup,
  Stack,
  Radio,
  Grid,
  useToast
} from '@chakra-ui/react';
import api from '../../../../utils/api';
import { IClientsTable } from '../../../../interfaces/Itable';
import { AxiosError } from 'axios';

interface EditClientProps {
  onClose: () => void;
  item:IClientsTable;
  setSelected?: React.Dispatch<React.SetStateAction<string>>
}

const EditClient: React.FC<EditClientProps> = ({ onClose,item,setSelected }: EditClientProps) => {
  const [formData, setFormData] = useState({
    clientName: '',
    clientImg: '',
    clientCountry: '',
    clientEmail: '',
    clientSkype: '',
    clientPhone: '',
    invited: false,
    notes: ''
  });
const toast=useToast();

  useEffect(() => {
    if (item) {
      setFormData({ ...item });
    }
  }, [item]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInvitedChange = (value: string) => {
    setFormData({ ...formData, invited: value === 'yes' });
  };

  const handleClick = async () => {
 
    try {
      const res = await api.patch('/portal/update_client/'+item?._id, formData);
      if (res.data) {
        console.log('Client EdIT successfully:', res.data);
        if (setSelected) {
          setSelected(Date.now().toString());
        }
        onClose(); 
        toast({
          title: 'Client data edit successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
     
      if (err instanceof AxiosError ) {
        toast({
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Error in edit the client:', err);
      }
    }
  };

  return (
    <>
       <ModalBody pb={6}>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <FormControl gridColumn="span 1">
            <FormLabel>Client name</FormLabel>
            <Input
              placeholder='Joe'
              name='clientName'
              onChange={handleChange}
              value={formData.clientName}
            />
          </FormControl>

          <FormControl gridColumn="span 1">
            <FormLabel>Client Image URL</FormLabel>
            <Input
              placeholder='Image URL'
              name='clientImg'
              onChange={handleChange}
              value={formData.clientImg}
            />
          </FormControl>

        <FormControl mt={4}>
          <FormLabel>Country</FormLabel>
          <Input
            placeholder='Country'
            name='clientCountry'
            onChange={handleChange}
            value={formData.clientCountry}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input
            placeholder='Email'
            name='clientEmail'
            onChange={handleChange}
            value={formData.clientEmail}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Skype</FormLabel>
          <Input
            placeholder='Skype'
            name='clientSkype'
            onChange={handleChange}
            value={formData.clientSkype}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Phone</FormLabel>
          <Input
            placeholder='Phone no.'
            name='clientPhone'
            onChange={handleChange}
            value={formData.clientPhone}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Invited</FormLabel>
          <RadioGroup value={formData.invited?"yes":"no"} onChange={(e) => handleInvitedChange(e)}>
            <Stack direction='row'>
              <Radio  value='yes' sx={{
                border: '2px solid',
                borderColor: 'blue.500',
                borderRadius: '50%',
         }}>Yes</Radio>
              <Radio  sx={{
                border: '2px solid',
                borderColor: 'blue.500',
                borderRadius: '50%',
         }}value='no'>No</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
</Grid>

      </ModalBody>

      <ModalFooter>
        <Button bg='#F36E21' color="white" _hover={{color:"black",bg:"#dfd6d68a"}} mr={3} onClick={handleClick}>
          Edit
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default EditClient;
