import { Icon } from "@iconify/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { IsummData } from "../interfaces/IsummData";
import Card from '../components/UI/card/Card';
import classes from '../components/summary/SummaryBox.module.scss'
const UserDetailBox: React.FC<{ item: IsummData }> = (props) => {
  const { t } = useTranslation();
  return (
    <div className={classes.summary__box}>
      <Card>
        <div className={classes.summary__box__wrapper}>
          <div className={classes.summary__box__icon}>
            <Icon icon={props.item.icon} width="56" />
          </div>
          <div className={classes.summary__box__info}>
            <p>{t(props.item.text)}</p>
            <div className={classes.summary__box__info__amount}>
              <h4>{props.item.amount}</h4>
              <sup>{t(props.item.currency)}</sup>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UserDetailBox;
