import { useState } from 'react';
import {
  Button,
  Select as Shivam,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  useToast
} from '@chakra-ui/react';

import { IJobCategoryTable, IPortalTable } from '../../../interfaces/Itable';
import api from '../../../utils/api';
import { AxiosError } from 'axios';

interface CreatePortalProfileProps {
  onClose: () => void;
  portal?: IPortalTable[];
  jobCat?: IJobCategoryTable[];
  setSelected2?:React.Dispatch<React.SetStateAction<string>>
}

const CreatePortalProfile: React.FC<CreatePortalProfileProps> = ({
  onClose,
  portal,
  setSelected2
  // jobCat
}: CreatePortalProfileProps) => {
  const [formData, setFormData] = useState({
    portalId: '',
    profileName: '',
    profileLink: '',
    // jobCategoryId: ''
  });

  const toast = useToast();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 
  
  

  const handleCreate = async() => {
    try {
      const res = await api.post(`/portal/create_profile`, formData);
      if (res.data){
        if(setSelected2){
          setSelected2(Date.now().toString());
        }
      toast({
        title: 'Portal Profile created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
   }
    } catch (err) {
      if (err instanceof AxiosError ) {
        toast({
       
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error(err);
      }
    }
    onClose();
    
  };

  return (
    <>
      <ModalBody pb={6}>
        <FormControl>
          <FormLabel>Portal name</FormLabel>
          <Shivam
            placeholder='Select option'
            name='portalId'
            onChange={handleChange}
            value={formData.portalId}
          >
            {portal?.map((el) => (
              <option key={el?._id} value={el?._id}>
                {el?.portalName}
              </option>
            ))}
          </Shivam>
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Profile Name</FormLabel>
          <Input

            placeholder='shivam..'
            name='profileName'
            onChange={handleChange}
            value={formData.profileName}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Profile link</FormLabel>
          <Input
            placeholder='https://...'
            name='profileLink'
            onChange={handleChange}
            value={formData.profileLink}
          />
        </FormControl>
        {/* <FormControl mt={4}>
      <FormLabel>
        Job Category
      </FormLabel>
      <Shivam
            placeholder='Select option'
            name='jobCategoryId'
            onChange={handleChange}
            value={formData.jobCategoryId}
          >
            {jobCat?.map((el) => (
              <option key={el?._id} value={el?._id}>
                {el?.jobName}
              </option>
            ))}
          </Shivam>
    </FormControl>
       */}
      </ModalBody>
      <ModalFooter>
        <Button bg= "#F36E21" color="white" _hover={{color:"black",bg:"#dfd6d68a"}} mr={3} onClick={handleCreate}>
          Create
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default CreatePortalProfile;
