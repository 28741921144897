import React, { useState } from "react";
import { useLocalStorage } from "usehooks-ts";

interface User {
  profilesAllocated: [];
  location: string;
  phoneNumber: string;
  _id: string;
  name: string;
  email: string;
  role: string;
  avatar: string;
  target:any;
}

type TContext = {
  isLogin: boolean;
  user:User;
  toggleLogin: () => void;
};

const LoginContext = React.createContext<TContext>({
  isLogin: false,
  user:{_id:"",name:"",email:"",role:"",avatar:"",location:"",phoneNumber:"",profilesAllocated:[],   target: undefined,},
  toggleLogin: () => {},
});

export const LoginContextProvider = ({children}: {
  children: React.ReactNode
}) => {
  const [isLogin, setIsLogin] = useLocalStorage("isLogin", false);
  const [userdata,setUserData]=useLocalStorage("isUser",{_id:"",name:"",email:"",role:"",avatar:""} as User);

  function toggleLogin() {
    setIsLogin((prev) => !prev);
   let data = localStorage.getItem("isUser") || '{}';
   let parsed=JSON.parse(data);
   setUserData(parsed);
  }

  const loginValue: TContext = {
    isLogin: isLogin,
    user:userdata,
    toggleLogin: toggleLogin,
  };

  return (
    <LoginContext.Provider value={loginValue}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
