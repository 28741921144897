import { useState } from 'react';
import { Button, FormControl, FormLabel, Input, ModalBody, ModalFooter, useToast } from '@chakra-ui/react';
import { IPortalTable } from '../../../../interfaces/Itable';
import api from '../../../../utils/api';
import { AxiosError } from 'axios';

const EditPortal = ({ onClose, item,setSelected  }: { onClose: () => void; item: IPortalTable ,setSelected?: React.Dispatch<React.SetStateAction<string>>}) => {
  const [editedItem, setEditedItem] = useState<IPortalTable>(item);
  const toast = useToast()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedItem(prevState => ({ ...prevState, [name]: value }));
  };



  const handleEdit = async () => {
    try {
      const res = await api.patch(`/portal/update/${item._id}`, editedItem);
      if (res.data) {
        // window.location.reload();
        if (setSelected) {
          setSelected(Date.now().toString());
        }
        toast({
          title: 'Portal has been successfully edited.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      if (err instanceof AxiosError ) {
        toast({
       
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error(err);
      }
    }
    onClose();
  };



  return (
    <>
      <ModalBody pb={6}>
        <FormControl>
          <FormLabel>Portal name</FormLabel>
          <Input
            name="portalName"
            value={editedItem.portalName || ''}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Portal Link</FormLabel>
          <Input
            name="link"
            value={editedItem.link || ''}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Portal Charges (%)</FormLabel>
          <Input
            name="charges"
            value={editedItem.charges || ''}
            onChange={handleChange}
          />
        </FormControl>

      </ModalBody>

      <ModalFooter>
        <Button bg="#F36E21" color="white" _hover={{color:"black",bg:"#dfd6d68a"}} mr={3} onClick={handleEdit}>
          Edit
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default EditPortal;
