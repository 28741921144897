import { useState, ChangeEvent, SetStateAction, Dispatch } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Button,
  useToast
} from '@chakra-ui/react';
import api from '../../../utils/api';
import { AxiosError } from 'axios';

interface PortalData {
  portalName: string;
  link: string;
  charges:number;
  setSelected?: Dispatch<SetStateAction<string>>;
}

const CreatePortal = ({ onClose,setSelected }: { onClose: () => void,setSelected?:Dispatch<SetStateAction<string>> }) => {

  const toast = useToast()
  const initialPortalData: PortalData = {
    portalName: "",
    link: "",
    charges:0
  };

  const [portalData, setPortalData] = useState<PortalData>(initialPortalData);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setPortalData({ ...portalData, [name]: value });
  };
  const handleCreate = async () => {
  
    try {
      const res = await api.post(`/portal/create`, portalData);
      if (res.data)
  if(setSelected){
    setSelected(Date.now().toString());

  }
      toast({
        title: 'Portal has been successfully created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      if (err instanceof AxiosError ) {
        toast({
       
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error(err);
      }
    }
    onClose();
  };

  return (
    <>
      <ModalBody pb={6}>
        <FormControl>
          <FormLabel>Portal name</FormLabel>
          <Input
        
            name='portalName'
            value={portalData.portalName}
            onChange={handleChange}
            placeholder="Upwork, Fiverr"
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Portal Link</FormLabel>
          <Input
            name='link'
            value={portalData.link}
            onChange={handleChange}
            placeholder="https://...."
          />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Portal Charges (%)</FormLabel>
          <Input
            name='charges'
            value={portalData.charges}
            onChange={handleChange}
            placeholder="Portal charges"
          />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button bg= "#F36E21" color="white" _hover={{color:"black",bg:"#dfd6d68a"}} mr={3} onClick={handleCreate}>
          Create
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default CreatePortal;
