import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import React from 'react';
import { IClientsTable, IJobCategoryTable, IPortalProfileTable, IPortalTable, IusersTable, complex } from '../../interfaces/Itable';
import EditJobCat from '../edit/editModal/edijJobCategory/EditJobCat';
import EditPortal from '../edit/editModal/editPortal/EditPortal';
import EditPortalProfile from '../edit/editModal/editProfile/EditPortalProfile';
import EditClient from '../edit/editModal/editClient/EditClient';
import EditUser from '../edit/editModal/editUser/EditUser';
    
  export default function EditModal({header,num,item,setSelected  }: {header:string,num:number | string,item: complex,setSelected?: React.Dispatch<React.SetStateAction<string>>}) {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef<HTMLInputElement>(null);
    const isPortalTable = (item: complex): item is IPortalTable => {
      return 'portalName' in item && 'link' in item && '_id' in item;
    };
    const isPortalPorfileTable = (item: complex): item is IPortalProfileTable => {
      return 'profileName' in item && 'portal' in item && '_id' in item;
    };

    const isPortalCategoryTable = (item: complex): item is IJobCategoryTable => {
      return 'jobName' in item && 'desc' in item;
    };

    const isClientTable = (item: complex): item is IClientsTable => {
      return 'clientName' in item;
    };

    const isUserTable = (item: complex): item is IusersTable => {
      return 'name' in item && 'target' in item;
    };

    return (
      <>
         <Icon onClick={onOpen} icon="fluent:edit-16-regular" width="24" />
  
        <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
   {num===11 && isUserTable(item)?  <EditUser item={item} onClose={onClose} setSelected={setSelected}/>:num===0 && isPortalTable(item)?  <EditPortal item={item} onClose={onClose} setSelected={setSelected}/>: num===1 && isPortalPorfileTable(item) ? <EditPortalProfile item={item} onClose={onClose} setSelected={setSelected}/>:  num===10 && isClientTable(item)? <EditClient item={item} onClose={onClose} setSelected={setSelected}/> : isPortalCategoryTable(item) && <EditJobCat item={item} onClose={onClose} setSelected={setSelected}/>}       
          </ModalContent>
        </Modal>
      </>
    );
  }
  