import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Stack,
  useToast,

} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import api from '../../../utils/api';
import LoginContext from '../../../store/loginContext';
import { AxiosError } from 'axios';

interface CreateClientProps {
  onClose: () => void;
  setSelectedClient?:React.Dispatch<React.SetStateAction<string>>
}

const CreateClient: React.FC<CreateClientProps> = ({ onClose,setSelectedClient }: CreateClientProps) => {
  const { user } = useContext(LoginContext);
  const [formData, setFormData] = useState({
    clientName: '',
    clientImg: '',
    clientCountry: '',
    clientEmail: '',
    clientSkype: '',
    clientPhone: '',
    invited: false,
    notes: '',
     creator: user?._id || ''
  });
  const toast = useToast();


 
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInvitedChange = (value: string) => {
    setFormData({ ...formData, invited: value === 'yes' }); 
  };

  const handleClick = async () => {
    console.log('Creating client:', formData.creator);
    try {
      const res = await api.post('/portal/create_client', formData);
      if (res.data) {
        console.log('Client created successfully:', res.data);
        if(setSelectedClient){
          setSelectedClient(Date.now().toString());
        }
        toast({
          title: 'Client created successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

        onClose(); 
      }
    } catch (err) {
    
      if(err instanceof AxiosError){
        toast({
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
      else{
        console.error('Error creating client:', err);
      }
      
    }
  };

  return (
    <>
       <ModalBody pb={6}>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <FormControl gridColumn="span 1">
            <FormLabel>Client name</FormLabel>
            <Input
              placeholder='Joe'
              name='clientName'
              onChange={handleChange}
              value={formData?.clientName}
            />
          </FormControl>

          <FormControl gridColumn="span 1">
            <FormLabel>Client Image URL</FormLabel>
            <Input
              placeholder='Image URL'
              name='clientImg'
              onChange={handleChange}
              value={formData?.clientImg}
            />
          </FormControl>

        <FormControl mt={4}>
          <FormLabel>Country</FormLabel>
          <Input
            placeholder='Country'
            name='clientCountry'
            onChange={handleChange}
            value={formData.clientCountry}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input
            placeholder='Email'
            name='clientEmail'
            onChange={handleChange}
            value={formData.clientEmail}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Skype</FormLabel>
          <Input
            placeholder='Skype'
            name='clientSkype'
            onChange={handleChange}
            value={formData.clientSkype}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Phone</FormLabel>
          <Input
            placeholder='Phone no.'
            name='clientPhone'
            onChange={handleChange}
            value={formData.clientPhone}
          />
        </FormControl>

       
        <FormControl mt={4}>
          <FormLabel>Invited</FormLabel>
          <RadioGroup defaultValue='no' onChange={(e) => handleInvitedChange(e)}>
            <Stack direction='row'>
              <Radio  value='yes'   sx={{
                border: '2px solid',
                borderColor: 'blue.500',
                borderRadius: '50%',
         }}>Yes</Radio>
              <Radio  value='no'   
              sx={{
                border: '2px solid',
                borderColor: 'blue.500',
                borderRadius: '50%',
              }}>No</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
</Grid>

      </ModalBody>

      <ModalFooter>
        <Button bg="#F36E21" color="white" _hover={{color:"black",bg:"#dfd6d68a"}} mr={3} onClick={handleClick}>
          Create
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default CreateClient;
