import { useContext } from "react";

import LoginContext from "../../../../store/loginContext";
import classes from "./Profile.module.scss";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t } = useTranslation();
  const {user} = useContext(LoginContext);

  return (
    <div className={classes.profile}>
      <div className={classes.profile__avatar}>
        <img src={user?.avatar} alt="avatar" />
      </div>
      <div className={classes.profile__info}>
        <p className={classes.profile__userName}>{user?.name}</p>
        <span className={classes.profile__role}>{t(user?.role)}</span>
      </div>
    </div>
  );
}

export default Profile;
