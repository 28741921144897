import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoadingSpinner from "./components/UI/loadingSpinner/LoadingSpinner";
import EditProfile from "./components/edit/editProfile/EditProfile";
import CreateProposal from "./components/proposal/CreateProposal";
import AuthLayout from "./layout/AuthLayout";
import MainLayout from "./layout/MainLayout";
import Clients from "./pages/Clients";
import PortalPage from "./pages/PortalPage";
import Proposal from "./pages/Proposal";
import Users from "./pages/Users";
import "./scss/App.scss";
import LoginContext from "./store/loginContext";
import HiredProposal from "./components/proposal/HiredProposal";
import UserDetail from "./pages/UserDetail";
import Upsell from "./pages/Upsell";
import CreateUpsell from "./components/proposal/Upsell";


// import OpenLead from "./pages/OpenLead";
// import CloseLead from "./pages/CloseLead";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const BlankPage = React.lazy(() => import("./pages/BlankPage"));
const Login = React.lazy(() => import("./pages/Login"));

const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"))
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"))




function App() {
  const {user}=useContext(LoginContext);

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="/proposals" element={<Proposal />} />
              <Route path="/proposals/create" element={<CreateProposal />} />
              <Route path="/proposals/hired/:id" element={<HiredProposal />} />
              <Route path="/proposals/edit/:id" element={<CreateProposal />} />
               <Route path="/clients" element={<Clients />} />
               <Route path="/profile" element={<EditProfile />} />
               <Route path="/portal" element={<PortalPage />} />
       

             {(user?.role==="admin" || user?.role==="manager") &&  <Route>
              <Route path="/users" element={<Users />} />
              <Route path="/users/:userId" element={<UserDetail />} />
              <Route path="/analytics" element={<BlankPage />} />
              <Route path="/discount" element={<BlankPage />} />
              <Route path="/inventory" element={<BlankPage />} />
              <Route path="/upsell" element={<Upsell />} />
              <Route path="/createupsell" element={<CreateUpsell/>} />
              <Route path="/upsell/edit/:id" element={<CreateUpsell/>} />
              </Route>}
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword/>} />

          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
