

const token = localStorage.getItem('chattoken') || '';
const user = JSON.parse(localStorage.getItem('isUser') || '{}');



const payload = {
  token,
  user: {
      _id: user?._id,
      name: user?.name,
      avatar: user?.avatar,
      password:user?.password
      // Include only essential fields
  }
};

// Serialize the payload and encode it into a Base64 string
const encodedPayload = btoa(JSON.stringify(payload));

let chatUrl = `http://localhost:3001/?data=${encodeURIComponent(encodedPayload)}`;

if (encodedPayload.length > 1800) {
  console.warn("Encoded payload is too long for a URL");
  // You can decide to truncate, split or handle this in another way
}




const sidebarNav = [
  {
    link: "/",
    section: "dashboard",
    icon: "lucide:layout-dashboard", //width:"20"
    text: "Dashboard",
  },
  {
    link: "/proposals",
    section: "proposals",
    icon: "ic:round-inventory",
    text: "Proposals",
  },
  {
    link: "/clients",
    section: "clients",
    icon: "ph:users-bold",
    text: "Clients",
  },
  {
    link: "/users",
    section: "users",
    icon: "ph:users-bold",
    text: "Users",
  },
 
  {
    link: "/portal",
    section: "portal",
    icon: "icon-park-outline:transaction-order",
    text: "Portal",
  },
 
 
  {
    link: "/profile",
    section: "profile",
    icon: "ep:setting",
    text: "Profile",
  },
  {
    link: "/upsell",
    section: "upsell",
    icon: "ic:round-inventory",
    text: "Upsell",

  },
  // {
  //   link: chatUrl,
  //   section: "chat",
  //   icon: "ic:round-inventory",
  //   text: "Chat",

  // },
];
export const sidebarNav2 = [
  {
    link: "/",
    section: "dashboard",
    icon: "lucide:layout-dashboard", //width:"20"
    text: "Dashboard",
  },
  {
    link: "/proposals",
    section: "proposals",
    icon: "ic:round-inventory",
    text: "Proposals",
  },
  {
    link: "/clients",
    section: "clients",
    icon: "ph:users-bold",
    text: "Clients",
  },
 
  {
    link: "/profile",
    section: "profile",
    icon: "ep:setting",
    text: "Profile",
  },
  // {
  //   link:chatUrl,
  //   section: "chat",
  //   icon: "ic:round-inventory",
  //   text: "Chat",

  // },
];

export default sidebarNav;
