import { Icon } from "@iconify/react";
import React, {useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoginContext from "../../../store/loginContext";
import Card from "../../UI/card/Card";
import Input from "../../UI/input/Input";
import classes from "../editCustomer/EditCustomer.module.scss";
import { Button, Flex, FormControl, useToast } from "@chakra-ui/react";
import api from "../../../utils/api";
import axios from "axios";

const EditProfile: React.FC = () => {
  const { t } = useTranslation();
  const nameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null)
  const confirmPasswordRef = useRef<HTMLInputElement>(null); 
  const phoneRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const {user}=useContext(LoginContext);
  const {avatar,email,_id,name}=user;
  const toast=useToast();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [load,setLoad]=useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        setLoad(true)
        const liveUrl = await uploadFileToCloudinary(file);
        setSelectedImage(liveUrl);
        setLoad(false)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };
  
  const uploadFileToCloudinary = async (file: File): Promise<string> => {
   if(!file){
    throw new Error("File not selected")   
   }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'x2yyfefc');
const config= {headers : {
  'Content-Type': 'multipart/form-data'
}};
    // Make a request to Cloudinary's upload API
    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/dpcpcrgqt/image/upload`,
      formData,config
    );
    // Assuming the response contains the live URL
    return response.data.secure_url;
  };
  
const handleClick=async()=>{
  if(passwordRef.current?.value){
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;
    if (!passwordRegex.test(passwordRef.current?.value || '')) {

      toast({
      
        description: "Password must be at least 6 characters, contain at least one uppercase letter, one number, and one special character",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      
      return;
    }

    if (
      passwordRef.current.value !== confirmPasswordRef.current?.value
    ) {
      toast({
        description: "Password do not match",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    const userConfirmed = window.confirm(`Are you sure you want to change the password to "${passwordRef.current.value}"?`);

    if (!userConfirmed) {
      return;
    } 
  }
  try {
    const res = await api.patch(`/users/${_id}`, {avatar:selectedImage?selectedImage:avatar,phoneNumber:phoneRef.current?.value?phoneRef.current?.value:user?.phoneNumber,name:nameRef.current?.value?nameRef.current?.value:name,location:addressRef.current?.value?addressRef.current?.value:user?.location,password:passwordRef.current?.value});
    if (res.data) {
      localStorage.setItem("isUser", JSON.stringify(res?.data));
    window.location.reload()
    toast({
      
      description: "Profile Edit Successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }
  } catch (err) {
    console.log(err);
    
  }
}

  return (
    <div className={classes.edit__container}>
      <div className={classes.edit__left}>
        <Card>
          <div className={classes.account}>
            <div className={classes.img_wrapper}>
              <img
                className={classes.avatar}
                src={avatar}
                alt="client avatar"
              />
            </div>
            <div className={classes.account__info}>
              <p>{t("AccountDetails")}</p>
              <div className={classes.account__info__userName}>
                <Icon icon="majesticons:user-line" width="24" />
                <div>{name}</div>
              </div>
            </div>
            <div className={classes.account__info}>
              <p>{t("contacts")}</p>
              <div className={classes.account__contact__phone}>
                <Icon icon="clarity:mobile-phone-solid" width="26" />
                <div>{user?.phoneNumber || 'N/A'}</div>
              </div>
              <div className={classes.account__contact__email}>
                <Icon icon="fontisto:email" width="24" />
                <div>{email}</div>
              </div>
              <div className={classes.account__contact__location}>
                <Icon icon="ep:map-location" width="28" />
                <div>{user?.location || "India"}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className={classes.edit__right}>
        <Card>
          <div className={classes.account}>
            <h3 className={classes.subTitle}>
              <Icon icon="fluent:edit-16-regular" width="24" />
              {t("edit")}
            </h3>
            <div className={classes.img_wrapper}>
              <div className={classes.upload_icon}>
                <Icon icon="akar-icons:cloud-upload" />
              </div>
              <div className={classes.file_input_control}>
                <input
                  className={classes.file_input}
                  type="file"
                  id="avatar"
                  name="avatar"
                  onChange={handleChangeImage}
                  accept="image/png, image/jpeg"
                />
              </div>
            
              <img
                className={classes.avatar}
                src={load?"https://images.unsplash.com/photo-1607434472257-d9f8e57a643d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bG9hZGluZ3xlbnwwfHwwfHx8MA%3D%3D" :selectedImage || avatar}
                alt="client avatar"
              />
            </div>
            <form autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* <Input
                id="name"
                ref={nameRef}
                type="text"
                placeholder={name}
              /> */}
              <Input
                id="phoneNumber"
                type="tel"
                ref={phoneRef}
                minLength={7}
                maxLength={12}
                placeholder={user?.phoneNumber}
                autocomplete="none"
              />


            <FormControl className={classes.password_field}>
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                minLength={7}
                ref={passwordRef}
                maxLength={12}
                placeholder={''}
                autocomplete="new-password" 
              />
                <span className={classes.eye_icon}>
                <Icon
                  icon={showPassword ?"icomoon-free:eye"  : "ion:eye-off-outline"}
                  width="24"
                  onClick={() => setShowPassword((prev) => !prev)}
                  // className={classes.eyeIcon}
                />
                </span>
                </FormControl>
                

             
              <FormControl className={classes.password_field}>
              <Input
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                minLength={7}
                ref={confirmPasswordRef}
                maxLength={12}
                classes="sdsdsd"
                // placeholder={"Confirm Password"}
                autocomplete="new-password"
              />
                <span className={classes.eye_icon}>
                <Icon
                  icon={showConfirmPassword ?"icomoon-free:eye"  : "ion:eye-off-outline"}
                  width="24"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
             
                />
                </span>
                </FormControl>
              
              <Input
                id="location"
                type="text"
                ref={addressRef}
                minLength={10}
                placeholder={user?.location}
              />
              <Flex gap={3} flexWrap={"wrap"}>
              <Button
  size='md'
  height='48px'
  width='200px'
  bg={'#F36E21'}
  color={'white'}
  _hover={{ bg: "#E65A10" }} 
  onClick={handleClick}
>{t("save")}</Button>

              </Flex>
            </form>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default EditProfile;
