import {
    Button,
    Modal,
    ModalCloseButton,
    ModalContent,
 ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import CreatePortal from '../create/createPortal/CreatePortal';
import CreatePortalProfile from '../create/createProfile/CreatePortalProfile';
import CreateJobCat from '../create/createJobCategory/CreateJobCat';
import { IJobCategoryTable, IPortalTable } from '../../interfaces/Itable';
import CreateClient from '../create/createClient/CreateClient';
interface ICreateModalProps {
  button: string;
  header: string;
  num: number;
  portal?: IPortalTable[];
  jobCat?: IJobCategoryTable[];
  setSelected?: Dispatch<SetStateAction<string>>;
  setSelected2?: Dispatch<SetStateAction<string>>;
  setSelected3?:Dispatch<SetStateAction<string>>;
  setSelectedClient?:Dispatch<SetStateAction<string>>;
}
  export default function CreateModal({button,portal,jobCat, header,num,setSelected,setSelected2 ,setSelected3,setSelectedClient}: ICreateModalProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef<HTMLInputElement>(null);
  
   
    return (
      <>
        <Button onClick={onOpen}>{button}</Button>
  
        <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>

            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
   {num===0?  <CreatePortal  onClose={onClose} setSelected={setSelected}/>: num===1 ? <CreatePortalProfile portal={portal} setSelected2={setSelected2} jobCat={jobCat} onClose={onClose}/> :  num===10 ? <CreateClient onClose={onClose} setSelectedClient={setSelectedClient}/> : <CreateJobCat onClose={onClose} setSelected3={setSelected3}/>}       
          </ModalContent>
        </Modal>
      </>
    );
  }
  