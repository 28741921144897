import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  useToast
} from '@chakra-ui/react';
import { IJobCategoryTable } from '../../../../interfaces/Itable';
import api from '../../../../utils/api';
import { AxiosError } from 'axios';


const EditJobCat= ({ onClose, item ,setSelected}: { onClose: () => void; item: IJobCategoryTable ,setSelected?: React.Dispatch<React.SetStateAction<string>>}) => {
  const [formData, setFormData] = useState({
    jobName: item.jobName || '', // Assuming item contains the job name
    desc:item.desc || '', // Placeholder for portal link - please replace with actual property
  });
const toast=useToast();


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = async() => {
  
    try {
      const res = await api.patch(`/portal/update_category/${item._id}`, formData);
      if (res.data) {
        // window.location.reload();
        if (setSelected) {
          setSelected(Date.now().toString());
        }
        toast({
          title: 'Job Category has been successfully edited.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      
      }
    } catch (err) {
      if (err instanceof AxiosError ) {
        toast({
       
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error(err);
      }
    }
    onClose();
  };

  return (
    <>
      <ModalBody pb={6}>
        <FormControl>
          <FormLabel>Job Category name</FormLabel>
          <Input
            placeholder="Job Category Name"
            name="jobName"
            value={formData.jobName}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Portal Link</FormLabel>
          <Input
            placeholder="Portal Link"
            name="desc"
            value={formData.desc}
            onChange={handleChange}
          />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button bg="#F36E21" color="white" _hover={{color:"black",bg:"#dfd6d68a"}} mr={3} onClick={handleEdit}>
          Edit
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default EditJobCat;
