import React from "react";
import RegisterBox from "../components/register/Register";

function Register() {
  return (
    <section>
      <RegisterBox />
    </section>
  );
}

export default Register;
