import Portal from "../components/portal/Portal";

function PortalPage() {
    
    return (
       <>
       <Portal/>
       </>
    );
}

export default PortalPage;
