import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginContext from '../store/loginContext';


interface State<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
  setData: React.Dispatch<React.SetStateAction<T | null>>;
  refetch:any
}

const useDataFetch = <T>(url: string): State<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const token=localStorage.getItem("usertoken")
  const loginCtx = useContext(LoginContext);

const navigate=useNavigate();

  const fetchData = useCallback(async (): Promise<void> => {

    try {
      setLoading(true);
      const response = await fetch(url,{
        headers: {Authorization: `Bearer ${token}`}
      });

      if (!response.ok) {
        if (response.status === 401) {
          loginCtx.toggleLogin();
          navigate('/login'); 
          return;
        }

        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json() as T;
      setData(result);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error);

      }
    } finally {
      setLoading(false);
     
    }
  }, [url]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  const refetch = () => {
    void fetchData();
  };

  return { data, loading, error, refetch,setData };
};

export default useDataFetch;
