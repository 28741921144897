import {useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useDataFetch from "../../hook/useDataFetch";
import { IJobCategoryTable, IPortalProfileTable, IPortalTable } from "../../interfaces/Itable";
import CreateModal from "../modal/CreateModal";
import CustomTable from "../tables/customTable/CustomTable";
import "./Portal.scss";
import LoadingSpinner from "../UI/loadingSpinner/LoadingSpinner";


function Portal() {
    const PortalUrl = `${process.env.REACT_APP_API_URL}/api/portal`
    const PortalPorfileUrl = `${process.env.REACT_APP_API_URL}/api/portal/profile`
    const PortalCategoryUrl = `${process.env.REACT_APP_API_URL}/api/portal/category`
    const { t } = useTranslation();
    const [selected, setSelected] = useState<string>("");
    const [selected2, setSelected2] = useState<string>("");
    const [selected3, setSelected3] = useState<string>("");
    const { data, error, loading, refetch } = useDataFetch<IPortalTable[]>(PortalUrl);
    const { data: profileData, error: profileError, loading: profileLoading, refetch: profileRefetch } = useDataFetch<IPortalProfileTable[]>(PortalPorfileUrl);
    const { data: categoryData, error: categoryError, loading: categoryLoading, refetch: categoryRefetch } = useDataFetch<IJobCategoryTable[]>(PortalCategoryUrl);

    let portalProfileTable;
    let portalTable;
    let categoryTable;



    useEffect(() => {

        if (selected) refetch()
        if (selected2) profileRefetch()
        if (selected3) categoryRefetch()

    }, [selected, selected2, selected3])


    if (loading) {
        portalTable = <LoadingSpinner />;
    }
    if (profileLoading) {
        portalProfileTable = <LoadingSpinner />;
    }
    if (categoryLoading) {
        categoryTable = <LoadingSpinner />;
    }
    if (error) {
        portalTable = (
            <CustomTable headData={[
                "ID",
                "Portal Name",
                "Link",
                "Actions"
            ]} bodyData={[]} limit={6} setSelected={setSelected} />)
    }
    if (profileError) {
        portalProfileTable = (
            <CustomTable headData={[
                "ID",
                "Profile Name",
                "Portal",
                // "Job Category",
                "Profile Link", "Actions"
            ]} bodyData={[]} limit={6} setSelected={setSelected2} />
        );
    }
    if (categoryError) {
        categoryTable = (
            <CustomTable headData={[
                "ID",
                "Job Category",
                "Description", "Actions"
            ]} bodyData={[]} limit={6} setSelected={setSelected3} />
        );
    }
    if (!loading && data) {
        portalTable = (
            <CustomTable headData={[
                "ID",
                "Portal Name",
                "Link",
                "Portal Charges (%)",
                "Actions"
            ]} bodyData={data} limit={6} setSelected={setSelected} />
        );
    }

    if (!profileLoading && profileData) {
        portalProfileTable = (
            <CustomTable headData={[
                "ID",
                "Profile Name",
                "Portal",
                // "Job Category",
                "Profile Link", "Actions"
            ]} bodyData={profileData} limit={6} setSelected={setSelected2} />
        );
    }


    if (!categoryLoading && categoryData) {
        categoryTable = (
            <CustomTable headData={[
                "ID",
                "Job Category",
                "Description", "Actions"
            ]} bodyData={categoryData} limit={6} setSelected={setSelected3} />
        );

    }

    return (
        <section>
            <div className="portal_creation">
                <h2 className="title">{t("Portal")}</h2>

                <CreateModal button={"Create Portal"} header={"Create portal"} num={0} setSelected={setSelected} /> </div>
            {portalTable}

            <div className="portal_creation">
                <h2 className="title">{t("Job Category")}</h2>
                <CreateModal button={"Create Job Category"} header={"Create Job Category"} num={2} setSelected3={setSelected3} /> </div>
            {categoryTable}

            <div className="portal_creation">
                <h2 className="title">{t("Portal Profile")}</h2>
                <CreateModal portal={data || undefined} jobCat={categoryData || undefined} button={"Create Portal Profile"} setSelected2={setSelected2} header={"Create portal Profile"} num={1} /> </div>
            {portalProfileTable}



        </section>
    );
}

export default Portal;
